import { Controller } from "@hotwired/stimulus"

class Clipboard extends Controller {
  static targets = ["source"]

  _getText(sourceMethod) {
    if (sourceMethod === "value") {
      return this.sourceTarget.value
    } else if (sourceMethod === "dataset") {
      return this.sourceTarget.dataset.clipboardText
    } else {
      console.error("Could not get text to copy to clipboard")
      return ""
    }
  }

  async copy({ params: { sourceMethod } }) {
    const text = this._getText(sourceMethod)
    if (text === "") {
      alert("Impossible de copier le texte dans le presse-papier")
      return
    }
    try {
      await navigator.clipboard.writeText(text)
      alert("La classe a bien été copiée dans le presse-papier.")
    } catch (err) {
      window.prompt("Copier dans le presse-papier : Cmd + C, Entrée", text)
    }
  }
}

export { Clipboard }
